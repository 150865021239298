<template>
  <div class="detail-view">
    <!--    <div class="close">-->
    <!--      <img alt="" class="image" src="../../assets/down@2x.png" @click="close"/>-->
    <!--    </div>-->
    <div class="detail">
      <div class="detail-left">
        <span class="company">{{ detail.orgName }}</span>
        <div class="type-list">
          <div v-for="(item ,index) in detail.orgTypeList" :key="index" class="title-bottom">
            <img :src="item === '1' ? machining : item === '2' ? intelligent : buisness" alt=""/>
            <span>{{ item === '1' ? '大板云工厂' : item === '2' ? '成品云工厂' : '材料商' }}</span>
          </div>
        </div>
      </div>
      <div class="detail-right">
        <div class="dacha">
          <img alt="" class="image" src="../../assets/icon_cancel@2x.png" @click="close"/>
        </div>
      </div>
    </div>
    <div class="detail detail1">
      <div class="detail-left detail-left1">
        <div class="boss">
          <img alt="" src="../../assets/profile-circle@2x.png"/>
          <span>{{ detail.contactName }}</span>
        </div>
        <div class="address1">
          <img alt="" src="../../assets/dingwei@2x.png"/>
          <span class="address">{{ detail.orgAddr }}</span>
        </div>
      </div>
      <div class="detail-right">
        <div class="btn" @click="toDetail(detail)">进店</div>
      </div>
    </div>
    <div class="uni-flex popup-buttons">
      <div class="uni-flex-item" @click="toGo">
        <img alt="" class="image image1" src="../../assets/Navigation@2x.png"/>
        导航
      </div>
      <div class="uni-flex-item item1" @click="toPhone">
        <img alt="" class="image" src="../../assets/call@2x.png"/>
        联系电话
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "marker-detail",
  props: {
    detail: {
      type: Object
    }
  },
  data() {
    return {
      intelligent: require('../../assets/icon_intelligent@2x.png'),
      machining: require('../../assets/icon_machining@2x.png'),
      buisness: require('../../assets/icon_buisness@2x.png')
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    toDetail(detail) {
      this.$emit('toDetail', detail)
    },
    toGo() {
      this.$emit('toGo')
    },
    toPhone() {
      this.$emit('toPhone')
    },
  }
}
</script>

<style lang="less" scoped>
.detail-view {
  //width: 100%;
  //height: 100%;
  //position: absolute;
  //bottom: 0;
  //left: 0;
  //right: 0;
  //background: #FFFFFF;
  //border-radius: 16px 16px 0px 0px;
  padding: 15px 15px 30px 15px;

  .close {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 12px;

    .image {
      width: 31px;
      height: 12px;
    }

  }

  .detail {
    display: flex;
    justify-content: space-between;

    .detail-left {
      display: flex;
      flex-direction: column;

      .company {
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 25px;
        margin-bottom: 5px;
      }

      .boss {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        color: #666666;
        line-height: 20px;
        margin-bottom: 9px;

        img {
          width: 16px;
          height: 16px;
          margin-right: 5px;
        }
      }

      .type-list {
        display: flex;
        justify-content: flex-start;
        flex-wrap: nowrap;
        margin-top: 10px;
        flex-direction: row;

        .title-bottom {
          margin-right: 5px;
          max-width: 82px;
          display: flex;
          flex-wrap: nowrap;
          flex-direction: row;
          border-radius: 2px;
          background: #F7F8FA;
          padding: 2px 4px;
          align-items: center;
          font-size: 12px;
          color: #ccc;
          font-weight: 400;

          &:last-child {
            margin-right: 0;
          }

          img {
            width: 12px;
            height: 12px;
            margin-right: 2px;
          }
        }
      }

      //.interal {
      //  margin: 0 15px;
      //  color: #DCDEE0;
      //}

      .address1 {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        img {
          width: 12px;
          height: 15px;
          margin-right: 5px;
        }

        .address {
          font-size: 12px;
          font-weight: 400;
          color: #666666;
          line-height: 17px;
        }
      }
    }

    .detail-left1 {
      width: calc(100% - 62px);
    }

    .detail-right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .dacha {
        width: 20px;
        height: 20px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .btn {
      margin-top: 10px;
      width: 50px;
      height: 28px;
      border-radius: 4px;
      border: 1px solid #02AF66;
      font-size: 12px;

      font-weight: 400;
      color: #02AF66;
      line-height: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .detail1 {
    margin-top: 15px;
    border-top: 1px solid #EBEDF0;
    padding-top: 15px;
  }

  .popup-buttons {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .uni-flex-item {
      width: 48%;
      height: 38px;
      background: #FFFFFF;
      border-radius: 19px;
      border: 1px solid #DCDEE0;
      font-size: 14px;
      font-family: PingFangSC-, PingFang SC;
      font-weight: normal;
      color: #333333;
      line-height: 14px;
      display: flex;
      justify-content: center;
      align-items: center;

      .image {
        width: 16px;
        height: 16px;
        margin-right: 5px;
      }

      .image1 {
        width: 14px;
        height: 14px;
      }

    }

    .item1 {
      color: #FFFFFF;
      background: #02AF66;
      border: 1px solid #02AF66;
    }

  }
}
</style>
